<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                class="ml-1"
                variant="primary"
                :to="{ name: 'admin-kiosk-add' }"
              >
                <span class="mr-25 align-middle">Create Kiosk</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        :items="tableData"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >

        <!-- Column: kiosk -->
        <template #cell(kiosk)="data">
          {{ data.item.kiosk }}
        </template>

        <!-- Column: campaign_id -->
        <template #cell(campaign_id)="data">
          {{ data.item.campaign_id }}
        </template>

        <!-- Column: processor -->
        <template #cell(processor)="data">
          {{ data.item.processor }}
        </template>

        <!-- Column: income -->
        <template #cell(income)="data">
          {{ data.item.income }}
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-success`"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'admin-kiosk-edit', params: { id: data.item.id } }"
              no-caret
            >
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'admin-kiosk-duplicate', params: { id: data.item.id } }"
              no-caret
            >
              <span>Duplicate</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="deletePost(data.item.id)"
            >
              <span>Delete Kiosk</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"></span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="tableData.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>

  </div>

</template>

<script>
import axios from '@axios'
import {
  BCard, BRow, BCol, BButton, BTable, BPagination,
  BDropdown, BDropdownItem, BBadge, VBModal,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    BButton,
    BPagination,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      filterDataProcessor: '',
      filterDataStatus: '',
      filterStatusOptions: [],
      filterProcessorOptions: [],
      perPage: 10,
      perPageOptions: [],
      tableColumns: [
        { key: 'title', label: 'kiosk', sortable: true },
        { key: 'slug', label: 'slug', sortable: true },
        { key: 'payment_processor.name', label: 'processor', sortable: true },
        { key: 'income', label: 'income', sortable: true },
        { key: 'actions' },
      ],
      tableData: [
      ],
      currentPage: '1',
    }
  },
  mounted() {
    this.getKiosk()
  },
  methods: {
    async getKiosk() {
      axios
        .get('/auth/kiosks')
        .then(response => {
          this.tableData = response.data.data
        })
    },
    deletePost(ID) {
      axios
        .delete(`/auth/kiosks/${ID}`)
        .then(() => {
          this.getKiosk()
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
